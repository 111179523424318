import Button from '@material-ui/core/Button';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type IFormButton = {
  text: string;
  color?: any;
  handler?: any;
  isDisabled?: boolean;
  primary?: boolean;
};

type IFormProps = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  children: any;
  buttons?: IFormButton[];
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  header: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  title: {
    fontSize: '1.25em',
    fontWeight: 'bold',
    color: 'var(--ion-color-dark)'
  },
  subtitle: {
    fontSize: '0.825em',
    color: 'var(--ion-color-dark)'
  },
  inputs: {
    margin: 20,
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      padding: 10
    }
  },
  footer: {
    margin: theme.spacing(2),
    '& .MuiButtonBase-root': {
      marginBottom: 10
    }
  }
}));

const Form = ({ title, subtitle, children, buttons = [] }: IFormProps) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <h2 className={classes.title}>{title}</h2>
        <p className={classes.subtitle}>{subtitle}</p>
      </div>
      <div className={classes.inputs}>{children}</div>
      <div className={classes.footer}>
        {buttons.map(
          (
            { text, color = 'primary', isDisabled = false, primary = true, handler }: IFormButton,
            i: number
          ) => (
            <Button
              key={i}
              size={primary ? 'medium' : 'small'}
              variant={primary ? 'contained' : 'text'}
              color={color}
              onClick={handler}
              disabled={isDisabled}
              fullWidth
            >
              {text}
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default Form;
