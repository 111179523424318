import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import RequestCard from '../../components/RequestCard';
import ReactCardSkeleton from '../../components/RequestCard/RequestCardSkeleton';
import Request from '../../entities/Request';

export interface RequestCardsProps {
  isLoading: boolean;
  requests: Request[];
  noneFoundMessage: string;
}

const useStyles = makeStyles((theme: any) => ({
  noRequestsFeedback: {
    fontWeight: 400,
    color: theme.palette.grey['500']
  }
}));

const Requests = ({ requests }) =>
  requests.map(request => <RequestCard key={request.id} request={request} />);

const Skeleton = () => (
  <>
    {Array(3)
      .fill(0)
      .map((_, i) => (
        <ReactCardSkeleton key={i} />
      ))}
  </>
);

const RequestCards: React.FC<RequestCardsProps> = ({ isLoading, requests, noneFoundMessage }) => {
  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : requests.length ? (
        <Requests requests={requests} />
      ) : (
        <Box display="flex" justifyContent="center" mt={6}>
          <Typography className={classes.noRequestsFeedback} variant="h5" component="h5">
            {noneFoundMessage}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default RequestCards;
