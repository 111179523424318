import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import _get from 'lodash.get';

import { REQUEST_NODE } from '../graphql/fragments/requestNode';
import useUser from './useUser';

export const SEARCH_REQUESTS = gql`
  query SearchRequests($createdBy: ID!) {
    requests(createdBy: $createdBy) {
      nodes {
        ...requestNode
      }
    }
  }
  ${REQUEST_NODE}
`;

export interface UseSearchRequestsProps {
  phone: string;
}

const useSearchRequests = (props: UseSearchRequestsProps) => {
  const { userInfo, userLoading } = useUser({ phone: `+1${props.phone}` });
  const userId = userInfo && userInfo.id;
  const { data, loading } = useQuery(SEARCH_REQUESTS, {
    skip: !userId,
    variables: { createdBy: userId },
    displayName: 'useSearchRequests',
    fetchPolicy: 'cache-and-network'
  });

  const requests = [..._get(data, 'requests.nodes', [])].sort((a, b) => b.createdAt - a.createdAt);

  return {
    userInfo,
    requests,
    userLoading,
    isLoading: loading
  };
};

export default useSearchRequests;
