import React from 'react';

import ActiveRequestCardsContainer from '../../components/ActiveRequestCardsContainer';
import CardGrid from '../../components/CardGrid';
import PageHeader from '../../components/PageHeader';
import QuickViews from '../../components/QuickViews';

const RequestsIndexPage: React.FC = () => {
  return (
    <>
      <PageHeader>
        <QuickViews />
      </PageHeader>
      <CardGrid>
        <ActiveRequestCardsContainer />
      </CardGrid>
    </>
  );
};

export default RequestsIndexPage;
