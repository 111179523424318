import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment';
import React from 'react';

import { Comment as CommentProps } from '../../entities/Request';
import useUser from '../../hooks/useUser';

const Comment: React.FC<CommentProps> = ({ content, author: authorId, createdAt }) => {
  const { userInfo } = useUser({ userId: authorId });
  const { firstName, lastName } = userInfo || { firstName: ' ', lastName: ' ' };
  const displayName = firstName + ' ' + lastName;
  const initials = firstName.charAt(0) + lastName.charAt(0);
  const timestamp = moment(Number(createdAt)).calendar();

  return (
    <Box display="flex" flexDirection="row" my={2}>
      <Box mr={2} flex="0 0 auto">
        <Avatar>{userInfo ? initials : <PersonIcon />}</Avatar>
      </Box>
      <Box flex="1 1 0%">
        {userInfo && (
          <Typography variant="subtitle1" gutterBottom>
            {displayName}
          </Typography>
        )}
        <Typography variant="body1" gutterBottom>
          {content}
        </Typography>
        <Typography color="textSecondary" variant="body1" gutterBottom>
          {timestamp}
        </Typography>
      </Box>
    </Box>
  );
};

export default Comment;
