import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import _get from 'lodash.get';

const GET_USER = gql`
  query GetUser($userId: ID, $phone: String) {
    user(id: $userId, phone: $phone) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export interface UseUserProps {
  userId?: string;
  phone?: string;
}

const useUser = (props: UseUserProps) => {
  const { data, loading } = useQuery(GET_USER, {
    variables: { userId: props.userId, phone: props.phone }
  });

  return { userInfo: _get(data, 'user'), userLoading: loading };
};

export default useUser;
