import { gql } from 'apollo-boost';

export const REQUEST_NODE = gql`
  fragment requestNode on RequestModel {
    id
    actions @client
    category
    categoryAbbreviation @client
    createdAt
    description
    geometry {
      center {
        latitude
        longitude
      }
    }
    location {
      address
    }
    locationDisplay @client
    photos {
      id
      src
    }
    status
    workorderId
  }
`;
