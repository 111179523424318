import { createMuiTheme } from '@material-ui/core';

export const categoryPalette = {
  default: {
    main: '#B0BEC5',
    border: 'rgba(255, 255, 255, 0.38)',
    contrastText: 'rgba(255, 255, 255, 0.87)'
  },
  drainage: {
    main: '#4DD0E1',
    border: 'rgba(255, 255, 255, 0.38)',
    contrastText: 'rgba(255, 255, 255, 0.87)'
  },
  roads: {
    main: '#85DAA7',
    border: 'rgba(255, 255, 255, 0.38)',
    contrastText: 'rgba(255, 255, 255, 0.87)'
  },
  signs: {
    main: '#B985DA',
    border: 'rgba(255, 255, 255, 0.38)',
    contrastText: 'rgba(255, 255, 255, 0.87)'
  },
  signals: {
    main: '#DAB385',
    border: 'rgba(255, 255, 255, 0.38)',
    contrastText: 'rgba(255, 255, 255, 0.87)'
  }
} as any;

export const theme = createMuiTheme({
  overrides: {
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#78909C',
        color: 'rgba(255, 255, 255, 0.54)'
      }
    },
    MuiCardHeader: {
      title: {
        marginBottom: 4
      },
      action: {
        marginRight: -16
      }
    },
    MuiDialogActions: {
      root: {
        padding: '8px 16px 16px'
      }
    },
    MuiInputBase: {
      input: {
        lineHeight: `${20 / 16}rem`
      }
    }
  },
  palette: {
    ...categoryPalette,
    background: {
      default: '#EEEEEE'
    },
    backdrop: 'rgba(0, 0, 0, 0.5)',
    divider: '#ECEFF1',
    icon: '#232F34',
    primary: {
      light: '#414C68',
      main: '#17243D',
      dark: '#000018',
      contrastText: '#FFFFFF'
    },
    secondary: {
      light: '#FFCCCF',
      main: '#FF4F60',
      dark: '#CC0238',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)'
    }
  },
  props: {
    MuiButtonBase: {
      focusRipple: true,
      TouchRippleProps: {
        style: {
          opacity: 0.5
        }
      }
    }
  },
  shape: {
    borderRadius: 2
  },
  typography: {
    fontSize: 13,
    h5: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
      lineHeight: `${26 / 16}rem`,
      letterSpacing: `${0.15 / 16}rem`
    },
    h6: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
      lineHeight: `${26 / 16}rem`,
      letterSpacing: `${0.15 / 16}rem`
    },
    subtitle1: {
      fontSize: `${16 / 16}rem`,
      fontWeight: 400,
      lineHeight: `${20 / 16}rem`,
      letterSpacing: `${0.25 / 16}rem`
    },
    body1: {
      fontSize: `${13 / 16}rem`,
      lineHeight: `${20 / 16}rem`,
      letterSpacing: `${0.15 / 16}rem`
    },
    button: {
      fontSize: `${14 / 16}rem`,
      fontWeight: 400,
      lineHeight: `${20 / 16}rem`,
      letterSpacing: `${0.25 / 16}rem`
    },
    caption: {
      fontSize: `${11 / 16}rem`,
      lineHeight: `${16 / 16}rem`,
      letterSpacing: `${0.15 / 16}rem`
    }
  }
});

/** Apply color contrast over dark UI elements. Used for photo overlay text. */
export const contrastTheme = createMuiTheme({
  ...theme,
  overrides: {
    MuiButton: {
      root: {
        color: 'rgba(255, 255, 255, 0.6)'
      }
    },
    MuiIconButton: {
      root: {
        color: 'rgba(255, 255, 255, 0.6)'
      }
    }
  },
  palette: {
    ...categoryPalette,
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.6)'
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.2)'
    }
  }
});
