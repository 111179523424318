import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

export interface ErrorSnackbarProps {
  isOpen: boolean;
  errorMessage: string;
  onClose: () => void;
}

const ErrorSnackbar = ({ isOpen, errorMessage, onClose }) => {
  return (
    <Snackbar open={isOpen} autoHideDuration={5000} onClose={onClose}>
      <MuiAlert onClose={onClose} elevation={6} variant="filled" severity="error">
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
