import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import React from 'react';

import Request from '../../entities/Request';
import { theme } from '../../theme';
import RequestComments from '../RequestComments';
import RequestPhotosCarousel from '../RequestPhotosCarousel';

export interface RequestDetailsProps {
  request: Request;
  userQueryLoading: boolean;
  userQueryData: {
    user: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
  };
}

const useStyles = makeStyles({
  actionButtonsContainer: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px -${theme.spacing(1)}px`,
    '& :not(:first-child)': {
      marginLeft: theme.spacing(1)
    }
  },
  container: {
    padding: theme.spacing(2, 0, 0, 0),
    '& > p': {
      margin: theme.spacing(2, 0)
    }
  },
  thickDivider: {
    height: 3
  }
});

const RequestDetails: React.FC<RequestDetailsProps> = props => {
  const { request, userQueryData, userQueryLoading } = props;
  const {
    createdAt,
    modifiedAt,
    location,
    description,
    id,
    category,
    status,
    comments,
    workorderId
  } = request;

  const classes = useStyles();

  const UserInfo = ({ userQueryData, userQueryLoading }) => {
    if (userQueryLoading) {
      return (
        <Box py={1}>
          <Skeleton variant="rect" width={300} height={20} />
        </Box>
      );
    }
    if (!userQueryData || !userQueryData.user) {
      return <Typography>Submitee info not found</Typography>;
    }
    const { firstName, lastName, email, phone } = userQueryData.user;
    const readeablePhone = `(${phone.slice(-10, -7)}) ${phone.slice(-7, -4)}-${phone.slice(-4)}`;
    return (
      <Typography>
        {firstName} {lastName} · {email} · {readeablePhone}
      </Typography>
    );
  };
  return (
    <Box className={classes.container}>
      <Typography variant="subtitle1" color="textPrimary">
        {location && location.address}
      </Typography>
      {workorderId && (
        <Box marginY={2}>
          <Link
            href={`https://workorders.lukeapps.io/workorders/details/${workorderId}`}
            color="secondary"
          >
            Open in Work with LUKE
          </Link>
        </Box>
      )}
      <Box height={400} mb={3} mx={-3}>
        <RequestPhotosCarousel request={request} />
      </Box>
      <Typography color="textSecondary">
        Created {moment(createdAt).fromNow()} · Last updated {moment(modifiedAt).fromNow()}
      </Typography>
      <Divider />
      <UserInfo userQueryData={userQueryData} userQueryLoading={userQueryLoading} />
      <Divider />
      <Typography>
        Category: {category} · Status: {status}
      </Typography>
      <Divider />
      <Typography>{description}</Typography>
      <Divider className={classes.thickDivider} />
      <RequestComments comments={comments} requestId={id} />
    </Box>
  );
};

export default RequestDetails;
