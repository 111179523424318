import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React from 'react';

import ErrorSnackbar from '../ErrorSnackbar';

export const INIT = gql`
  query Init {
    errorSnackbar @client {
      isOpen
      message
    }
  }
`;

export const HIDE_ERROR = gql`
  mutation hideError {
    hideError @client
  }
`;

const ErrorSnackbarContainer = () => {
  const { data } = useQuery(INIT, { displayName: 'ErrorSnackbarContainer' });
  const [hideError] = useMutation(HIDE_ERROR);

  return (
    <ErrorSnackbar
      isOpen={data.errorSnackbar.isOpen}
      errorMessage={data.errorSnackbar.message}
      onClose={hideError}
    />
  );
};

export default ErrorSnackbarContainer;
