import merge from 'lodash.merge';

import { resolvers as authenticationResolvers, initialState as authState } from './authentication';
import { resolvers as messageResolvers, initialState as messageState } from './messages';
import { resolvers as errorSnackbarResolvers } from './errorSnackbar';
import initialStateTemp from './initialStateTemp';
import { resolvers as quickViewResolvers, initialState as quickViewState } from './quickView';
import { resolvers as requestResolvers, initialState as requestState } from './request';

const initialState = {
  ...messageState,
  ...authState,
  ...quickViewState,
  ...requestState,
  ...initialStateTemp
};

const resolvers = merge(
  messageResolvers,
  authenticationResolvers,
  quickViewResolvers,
  requestResolvers,
  errorSnackbarResolvers
);

export { initialState, resolvers };
