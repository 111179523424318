import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

export interface DenyRequestDialogProps {
  isOpen: boolean;
  requestId: string;
  onDenyRequest: ({ variables: { id, reason } }) => void;
  onCancelDenyRequest: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: theme.shape.borderRadius,
      minWidth: 320
    }
  })
);

const DenyRequestDialog = ({
  isOpen,
  requestId,
  onDenyRequest,
  onCancelDenyRequest
}: DenyRequestDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [reason, setReason] = useState('');

  useEffect(() => {
    setReason('');
  }, [isOpen]);

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const canSubmit = reason.length;

  return (
    <Dialog
      PaperProps={{ className: classes.paper }}
      fullScreen={fullScreen}
      open={isOpen}
      onClose={onCancelDenyRequest}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <DialogTitle>Deny Request</DialogTitle>
        <DialogActions>
          <IconButton onClick={onCancelDenyRequest} aria-label="cancel">
            <CancelIcon fontSize="small" />
          </IconButton>
        </DialogActions>
      </Box>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          label="Reason the request is denied"
          margin="none"
          multiline
          placeholder="This request is outside of our service area"
          rows="4"
          value={reason}
          variant="outlined"
          onChange={handleDescriptionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onDenyRequest({ variables: { id: requestId, reason } })}
          color="secondary"
          disabled={!canSubmit}
          fullWidth={fullScreen}
        >
          Deny Request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DenyRequestDialog;
