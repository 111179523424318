import { RequestStatus } from '../entities/Request';
import ApproveAction from '../requestActions/ApproveAction';
import CompleteAction from '../requestActions/CompleteAction';
import DenyAction from '../requestActions/DenyAction';

export interface InitialState {
  denyDialog: {
    __typename: 'DenyDialog';
    requestId: string | null;
    isOpen: boolean;
  };
}

export const initialState = {
  denyDialog: {
    __typename: 'DenyDialog',
    requestId: null,
    isOpen: false
  }
};

export const resolvers = {
  Mutation: {
    startDenyRequest: (_, { input: { requestId } }, { cache }) => {
      cache.writeData({
        data: { denyDialog: { requestId, isOpen: true, __typename: 'DenyDialog' } }
      });
    },
    cancelDenyRequest: (_, __, { cache }) => {
      cache.writeData({
        data: { denyDialog: { requestId: null, isOpen: false, __typename: 'DenyDialog' } }
      });
    }
  },
  RequestModel: {
    categoryAbbreviation: ({ category }) => category.slice(0, 1),
    locationDisplay: ({ location }) =>
      location && location.address
        ? location.address.replace(/, Texas \d*, United States/, '')
        : 'No Location Available',
    map: () => ({ id: 'map', src: '/nomap.png' }),
    actions: ({ status }, _, { client }) => {
      const approveAction = new ApproveAction(client.mutate);
      const denyAction = new DenyAction(client.mutate);
      const completeAction = new CompleteAction(client.mutate);

      if (status === RequestStatus.Submitted) {
        return [approveAction, denyAction, completeAction];
      }
      if (status === RequestStatus.Approved) {
        return [completeAction];
      }
      return [];
    }
  }
};
