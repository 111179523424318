import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    '& > div': {
      margin: `${theme.spacing(1)}px 0`
    }
  }
}));

const RequestDetailsSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Skeleton variant="rect" height={20}></Skeleton>
      <Skeleton variant="rect" height={20} width="50%"></Skeleton>
      <Skeleton variant="rect" height={560}></Skeleton>
      <Skeleton variant="rect" height={20} width="50%"></Skeleton>
      <Divider />
      <Skeleton variant="rect" height={20} width="50%"></Skeleton>
      <Divider />
      <Skeleton variant="rect" height={20} width="50%"></Skeleton>
      <Divider />
      <Skeleton variant="rect" height={80}></Skeleton>
    </Box>
  );
};

export default RequestDetailsSkeleton;
