import Box from '@material-ui/core/Box';
import Form from '../../components/Form';
import PhoneInput from '../../components/PhoneInput';
import useAuthFlow, { ChallengeMethods } from '../../hooks/useAuthFlow';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MessageSnackbar from '../../containers/MessageSnackbar';

const PhoneChallenge: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const { switchMethod, startChallenge, isValidInput, data } = useAuthFlow();
  const { recipient, reissue } = data;
  const [phone, setPhone] = useState(decodeURI(params.phone) || recipient || '');
  const [validated, setValidated] = useState<boolean>(false);

  const checkValidation = useCallback(
    () => phone && setValidated(isValidInput(ChallengeMethods.Phone, phone)),
    [phone, setValidated, isValidInput]
  );
  const handleNext = useCallback(() => {
    if (validated && phone) {
      startChallenge({ method: ChallengeMethods.Phone, recipient: phone, reissue });
    }
  }, [validated, phone, reissue, startChallenge]);

  /** Responsible for checking the validity of phone input */
  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  const subtitle = (
    <span>
      Provide your <strong>phone number</strong> to login or to begin a new registration.
    </span>
  );

  const buttons = [
    {
      text: 'Next',
      handler: handleNext,
      isDisabled: !validated
    },
    {
      text: 'Login with email instead?',
      color: 'secondary',
      primary: false,
      handler: () => switchMethod(ChallengeMethods.Email)
    }
  ];

  return (
    <Box color="background">
      <MessageSnackbar />
      <Form title="Welcome to P3 Works - Work Orders" subtitle={subtitle} buttons={buttons}>
        <PhoneInput autoFocus value={phone} onChange={setPhone} fullWidth />
      </Form>
    </Box>
  );
};

export default PhoneChallenge;
