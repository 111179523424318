import { createStyles, makeStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import RequestDetailsContainer from '../RequestDetailsContainer';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    backIcon: {
      position: 'absolute',
      color: (props: any) =>
        `${props.mediaQuery ? theme.palette.grey['500'] : theme.palette.grey['200']}`,
      zIndex: 10,
      top: theme.spacing(2),
      right: theme.spacing(2)
    },
    container: {
      maxWidth: '800px',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2, 3),
      margin: theme.spacing(6, 0),
      backgroundColor: theme.palette.background.paper,
      '&:focus': {
        outline: 0
      }
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'scroll'
    },
    root: {
      backgroundColor: (props: any) =>
        `${props.mediaQuery ? theme.palette.background.paper : theme.palette.backdrop}`,
      '&:hover': {
        cursor: (props: any) => `${props.mediaQuery ? 'auto' : 'pointer'}`
      }
    },
    scrollBody: {
      width: '800px',
      height: '100%',
      outline: 0
    }
  })
);

const RequestDetailsModal = () => {
  const mediaQuery = useMediaQuery('(max-width:960px)');
  const classes = useStyles({ mediaQuery });
  const history = useHistory();
  const { id } = useParams();

  return (
    <Modal
      className={classes.modal}
      open={true}
      disableBackdropClick={mediaQuery}
      onClose={() => history.goBack()}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.root
      }}
    >
      <Fade in={true}>
        <Box className={classes.scrollBody}>
          <Box className={classes.container}>
            <IconButton className={classes.backIcon} onClick={() => history.goBack()}>
              <CloseIcon fontSize="large" />
            </IconButton>
            {id && <RequestDetailsContainer id={id} />}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default RequestDetailsModal;
