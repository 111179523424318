import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import useMessage from '../hooks/useMessage';

// Maps the Message.color to Alert.severity
enum ColorMap {
  danger = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success'
}

const MessageSnackbar = () => {
  const { message, display, clearMessage } = useMessage();
  const anchor: any = { vertical: 'top', horizontal: 'center' };
  return (
    <Snackbar open={display} autoHideDuration={5000} onClose={clearMessage} anchorOrigin={anchor}>
      <MuiAlert
        onClose={clearMessage}
        elevation={6}
        variant="filled"
        color={ColorMap[message.color]}
      >
        {message.content}
      </MuiAlert>
    </Snackbar>
  );
};

export default MessageSnackbar;
