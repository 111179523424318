import Box from '@material-ui/core/Box';
import EmailInput from '../../components/EmailInput';
import Form from '../../components/Form';
import useAuthFlow, { ChallengeMethods } from '../../hooks/useAuthFlow';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MessageSnackbar from '../../containers/MessageSnackbar';

const EmailChallenge: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const { switchMethod, startChallenge, isValidInput, data } = useAuthFlow();
  const { recipient, reissue } = data;
  const [email, setEmail] = useState(params.email || recipient || '');
  const [validated, setValidated] = useState<boolean>(false);

  const checkValidation = useCallback(
    () => email && setValidated(isValidInput(ChallengeMethods.Email, email)),
    [email, setValidated, isValidInput]
  );
  const handleNext = useCallback(() => {
    if (validated && email) {
      startChallenge({ method: ChallengeMethods.Email, recipient: email, reissue });
    }
  }, [validated, email, reissue, startChallenge]);

  /** Responsible for checking the validity of email input */
  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  const subtitle = (
    <span>
      Provide your <strong>email address</strong> to login.
    </span>
  );

  const buttons = [
    {
      text: 'Next',
      handler: handleNext,
      isDisabled: !validated
    },
    {
      text: 'Login with phone number instead?',
      color: 'secondary',
      primary: false,
      handler: () => switchMethod(ChallengeMethods.Phone)
    }
  ];

  return (
    <Box color="background">
      <MessageSnackbar />
      <Form title="Welcome to P3 Works - Work Orders" subtitle={subtitle} buttons={buttons}>
        <EmailInput autoFocus value={email} onChange={setEmail} fullWidth />
      </Form>
    </Box>
  );
};

export default EmailChallenge;
