import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

const useRouterPath = () => {
  const location = useLocation();
  const history = useHistory();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  return {
    location,
    history,
    params
  };
};

export default useRouterPath;
