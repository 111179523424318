import { InMemoryCache } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import gql from 'graphql-tag';

import { resolvers, initialState } from '../graphql';

const { REACT_APP_GRAPHQL_ENDPOINT: GRAPHQL_URI } = process.env;

export class StoreService {
  private versionKey = 'apollo-schema-version';
  private storage: any;
  private cache: any;
  private persistor: any;
  private client: any;
  private token: string | null = null;

  constructor(private version: string = '1.0') {
    // Setup localstorage as the persistence storage
    this.storage = window.localStorage;
    // Initialize the cache as InMemoryCache
    this.cache = new InMemoryCache();
    // Add persistence to the cache
    this.persistor = new CachePersistor({
      cache: this.cache,
      storage: this.storage
    });

    // Check and update schema version
    this.checkVersion();
    // Setup the apollo client
    this.setupApollo();
  }

  async checkVersion() {
    // Read the current schema version from storage
    const currentVersion = this.storage.getItem(this.versionKey);

    // If current version matches the latest version
    if (currentVersion === this.version) {
      // we're good to go and can restore the cache.
      await this.persistor.restore();
    } else {
      // Otherwise, we'll want to purge the outdated persisted cache and update version to storage
      await this.persistor.purge();
      await this.storage.setItem(this.versionKey, this.version);
    }
  }

  async setupApollo() {
    // Setup any context injection

    // Middleware to set the auth headers
    const authMiddleware = setContext(async (_, { headers, ...context }) => {
      const token = this.getToken();

      return {
        ...context,
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : null
        }
      };
    });

    const handleError = onError(({ graphQLErrors, networkError }) => {
      // tslint:disable no-console
      if (graphQLErrors)
        graphQLErrors.forEach(({ code, message, locations, path }: any) => {
          if (code === 'INTERNAL_SERVER_ERROR' && message.includes('Access denied')) {
            this.client.resetStore();
          }

          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
      // tslint:enable no-console
    });

    const withUpload = createUploadLink({
      uri: GRAPHQL_URI,
      credentials: 'same-origin'
    });

    // Initialize Apollo Client
    this.client = new ApolloClient({
      link: ApolloLink.from([handleError, authMiddleware, withUpload]),
      cache: this.cache,
      resolvers
    });

    // Set apollo client cache to default state
    this.cache.writeData({ data: initialState });

    this.client.onResetStore(async () => {
      await this.persistor.purge();
      this.token = null; // Reset local cached token
      this.client.cache.writeData({ data: initialState });
    });
  }

  getClient() {
    return this.client;
  }

  getToken() {
    // Return local token if available.
    // If no local token, pull from cache and set local for later use.
    // If neither are available, return `null`

    if (this.token) return this.token;

    const cache = this.tokenFromCache();
    if (cache) {
      this.token = cache;
      return this.token;
    }

    return null;
  }

  private tokenFromCache(): string | null {
    let data;

    const query = gql`
      query AccessToken {
        accessToken @client
      }
    `;

    try {
      data = this.cache.readQuery({ query });
    } catch {
      return null;
    }

    return (data && data.accessToken) || null;
  }
}
