import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import { useParams } from 'react-router-dom';

import RequestDetailsContainer from '../../components/RequestDetailsContainer';

const useStyles = makeStyles((theme: any) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(3)}px auto`
    },
    padding: theme.spacing(2, 3),
    maxWidth: 800
  }
}));

const RequestDetailsPage: React.FC = () => {
  const { id } = useParams();
  const classes = useStyles();

  return <Box className={classes.container}>{id && <RequestDetailsContainer id={id} />}</Box>;
};

export default RequestDetailsPage;
