import { gql } from 'apollo-boost';
import find from 'lodash.find';

import { RequestStatus } from '../entities/Request';

export interface QuickViewState {
  __typename: 'QuickView';
  id: string;
  name: string;
  filter: { __typename: 'QuickViewFilter'; status: RequestStatus };
}

export interface InitialState {
  activeQuickView: string;
  quickViews: QuickViewState[];
}

export const initialState = {
  activeQuickView: '0',
  quickViews: [
    {
      __typename: 'QuickView',
      id: '0',
      name: 'New',
      filter: { __typename: 'QuickViewFilter', status: RequestStatus.Submitted }
    },
    {
      __typename: 'QuickView',
      id: '1',
      name: 'In Progress',
      filter: { __typename: 'QuickViewFilter', status: RequestStatus.Approved }
    }
  ]
};

export const resolvers = {
  Mutation: {
    setActiveQuickView: (_, { input: { id } }, { cache }): string => {
      const query = gql`
        {
          quickViews @client {
            id
          }
        }
      `;

      const { quickViews } = cache.readQuery({ query });
      const match = find(quickViews, { id });

      if (!match) {
        throw new Error(`Unable to set active Quick View. Input id ${id} is not valid.`);
      }

      cache.writeData({ data: { activeQuickView: id } });

      return id;
    }
  }
};
