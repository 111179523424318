import { gql } from 'apollo-boost';

import RequestActionBase from './RequestAction';

export const APPROVE = gql`
  mutation approveRequest($requestId: ID!) {
    approveRequest(input: { requestId: $requestId }) {
      request {
        id
        status
        actions @client
      }
    }
  }
`;

export default class ApproveAction extends RequestActionBase {
  title = 'Approve';
  action(requestId: string) {
    this.mutateFn({ mutation: APPROVE, variables: { requestId } });
  }
}
