import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 320 - theme.spacing(2) * 2 // See RequestCard comp for logic
  },
  cardActions: {
    padding: theme.spacing(1, 0.5),
    justifyContent: 'flex-start'
  },
  category: {
    flex: '0 0 auto',
    height: 36,
    width: 36,
    marginRight: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius
  },
  divider: {
    margin: theme.spacing(0, 2)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    top: 0,
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 1,
    padding: theme.spacing(1.5)
  }
}));

const RequestCardSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box height={200}>
        <Box className={classes.titleContainer}>
          <Skeleton variant="rect" className={classes.category} />
          <Box
            flex="1 1 0%"
            height={36}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Skeleton width="75%" height={10} variant="text" />
            <Skeleton height={20} variant="text" />
          </Box>
        </Box>
      </Box>
      <Box height={92} pt={0.5} px={2}>
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <Box key={i} mt={0.5}>
              <Skeleton variant="text" />
            </Box>
          ))}
      </Box>
      <Divider className={classes.divider} />
      <CardActions className={classes.cardActions} disableSpacing={true}>
        {Array(2)
          .fill(0)
          .map((_, i) => (
            <Box key={i} width={60} mx={1.5} my={1}>
              <Skeleton variant="text" />
            </Box>
          ))}
      </CardActions>
    </Card>
  );
};

export default RequestCardSkeleton;
