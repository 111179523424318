import { RequestAction } from '../requestActions/RequestAction';

export enum RequestStatus {
  Submitted = 'Submitted',
  Approved = 'Approved',
  Denied = 'Denied'
}

export enum RequestCategory {
  Drainage = 'Drainage',
  Roads = 'Roads',
  Signals = 'Signals',
  Signs = 'Signs'
}

export class Location {
  address?: string;
  neighborhood?: string;
}

export interface Geometry {
  center: {
    latitude: number;
    longitude: number;
  };
}

export interface Photo {
  id: string;
  src: string;
}

export interface Comment {
  id: string;
  content: string;
  author: string;
  createdAt: string;
}

/** Request as returned from the API */
export interface ApiRequest {
  id: string;
  category: RequestCategory;
  description: string;
  geometry: Geometry;
  location?: Location;
  status: RequestStatus;
  createdAt: string;
  createdBy?: string;
  modifiedAt?: string;
  photos: Photo[];
  comments: Comment[];
  workorderId?: string;
}

export default interface Request extends ApiRequest {
  actions: RequestAction[];
  categoryAbbreviation: string;
  locationDisplay: string;
}
