import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(8),
    background: theme.palette.background.paper
  }
}));

const PageHeader = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.userInfo}>
      <Container>{children}</Container>
    </Box>
  );
};

export default PageHeader;
