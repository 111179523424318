import Box from '@material-ui/core/Box';
import CodeInput from '../../components/CodeInput';
import Form from '../../components/Form';
import useAuthFlow from '../../hooks/useAuthFlow';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MessageSnackbar from '../../containers/MessageSnackbar';

const VerifyChallenge: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const { startChallenge, verifyChallenge, formatRecipient, data } = useAuthFlow(params);
  const { method, userId, challengeId, recipient } = data;
  const [code, setCode] = useState('');
  const [validated, setValidated] = useState<boolean>(false);
  const checkValidation = useCallback(
    () => setValidated(code && code.length === 6 ? true : false),
    [code, setValidated]
  );
  const handleNext = useCallback(() => {
    if (validated && userId && challengeId) {
      verifyChallenge({ userId, challengeId, answer: code });
      setCode('');
    }
  }, [userId, challengeId, code, validated, verifyChallenge, setCode]);

  /** Responsible for checking the validity of code input */
  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  const subtitle = (
    <span>
      A verification code was sent to <strong>{recipient && formatRecipient(recipient)}</strong>.
    </span>
  );

  const buttons = [
    {
      text: 'Next',
      handler: handleNext,
      isDisabled: !validated
    },
    {
      text: 'No code?',
      color: 'secondary',
      primary: false,
      handler: () => startChallenge({ method, recipient, reissue: true })
    }
  ];

  return (
    <Box color="background">
      <MessageSnackbar />
      <Form title="Verification Code OTP" subtitle={subtitle} buttons={buttons}>
        <CodeInput autoFocus value={code} onChange={setCode} fullWidth />
      </Form>
    </Box>
  );
};

export default VerifyChallenge;
