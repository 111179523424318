import Grid from '@material-ui/core/Grid';
import React from 'react';

import { QuickView } from '../../entities/QuickView';
import useQuickViews from '../../hooks/useQuickViews';
import QuickViewButton from '../QuickViewButton';

export interface QuickViewProps {
  quickViews: QuickView[];
  onSetActive: (quickViewId: string) => void;
}

export const QuickViews: React.FC<QuickViewProps> = ({ quickViews, onSetActive }) => (
  <Grid container justify="flex-start" spacing={1}>
    {quickViews.map(({ id, isActive, name }: QuickView, i) => (
      <Grid item key={i}>
        <QuickViewButton
          name={name}
          isActive={isActive}
          isDisabled={false}
          onClick={() => onSetActive(id)}
        />
      </Grid>
    ))}
  </Grid>
);

const QuickViewsContainer: React.FC = () => {
  const { quickViews, setActive } = useQuickViews();
  return <QuickViews quickViews={quickViews} onSetActive={setActive} />;
};

export default QuickViewsContainer;
