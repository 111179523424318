import { MutationOptions, FetchResult } from 'apollo-boost';

export interface RequestAction {
  title: string;
  action(requestId: string): void;
}

export default abstract class RequestActionBase implements RequestAction {
  abstract title: string;
  abstract action(requestId: string): void;
  constructor(protected mutateFn: (options: MutationOptions) => Promise<FetchResult>) {}
}
