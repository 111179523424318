import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useState } from 'react';

import SearchPhoneInput from '../../components/SearchPhoneInput';
import useRouterPath from '../../hooks/useRouterPath';

const SearchPhoneInputContainer: React.FC = () => {
  const { history, location } = useRouterPath();
  const [phone, setPhone] = useState('');

  const checkLocation = () => {
    if (location.pathname === '/app/search') {
      history.push(`/app/requests`);
    }
  };

  const onSearch = phoneNumber => {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');

    if (parsedNumber) {
      history.push(`/app/search?phone=${parsedNumber.nationalNumber}`);
    }
  };

  const onClearPhone = () => {
    setPhone('');
    checkLocation();
  };

  const onChangePhone = phoneNumber => {
    setPhone(phoneNumber);
    if (phoneNumber.length !== 14) {
      checkLocation();
      return;
    }
    onSearch(phoneNumber);
  };

  return <SearchPhoneInput value={phone} onChange={onChangePhone} onClear={onClearPhone} />;
};

export default SearchPhoneInputContainer;
