import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

export interface QuickViewButtonProps {
  isActive: boolean;
  isDisabled: boolean;
  name: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  button: {
    padding: theme.spacing(1, 2),
    '&:disabled': {
      borderColor: theme.palette.action.disabled
    }
  },
  active: {
    backgroundColor: theme.palette.secondary.light,
    '&, &:disabled': {
      color: theme.palette.secondary.dark
    }
  }
}));

const QuickViewButton: React.FC<QuickViewButtonProps & ButtonProps> = ({
  isActive,
  isDisabled,
  name,
  onClick,
  className
}) => {
  const classes = useStyles();

  return (
    <Button
      color="secondary"
      className={clsx(className, classes.button, { [classes.active]: isActive })}
      disabled={isDisabled || isActive}
      onClick={() => onClick()}
    >
      {name}
    </Button>
  );
};

export default QuickViewButton;
