import { useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError, gql } from 'apollo-boost';
import React from 'react';

import DenyRequestDialog from '../DenyRequestDialog';

export const INIT = gql`
  query Init {
    denyDialog @client {
      requestId
      isOpen
    }
  }
`;

export const CANCEL_DENY_DIALOG = gql`
  mutation cancelDenyRequest {
    cancelDenyRequest @client
  }
`;

export const DENY_REQUEST = gql`
  mutation denyRequest($id: ID!, $reason: String!) {
    denyRequest(input: { requestId: $id, reason: $reason }) {
      request {
        id
        status
        actions @client
      }
    }
  }
`;

export const SHOW_ERROR = gql`
  mutation showError($message: string!) {
    showError(input: { message: $message }) @client
  }
`;

const DenyRequestDialogContainer = () => {
  const { data } = useQuery(INIT, { displayName: 'DenyRequestDialogContainer' });
  const [cancelDenyRequest] = useMutation(CANCEL_DENY_DIALOG);
  const [showError] = useMutation(SHOW_ERROR);
  const [denyRequest] = useMutation(DENY_REQUEST, {
    refetchQueries: ['requests'],
    onCompleted: () => cancelDenyRequest(),
    onError: (error: ApolloError) => showError({ variables: { message: error.message } })
  });

  return (
    <DenyRequestDialog
      isOpen={data.denyDialog.isOpen}
      requestId={data.denyDialog.requestId}
      onDenyRequest={denyRequest}
      onCancelDenyRequest={cancelDenyRequest}
    />
  );
};

export default DenyRequestDialogContainer;
