import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export interface UserInfoProps {
  userLoading: boolean;
  phone: string;
  userInfo: UserInfo | null;
}

const useSkeletonStyles = makeStyles(() => ({
  userInfoSkeleton: {
    display: 'flex',
    '& > span': {
      padding: '0 5px'
    }
  }
}));

const UserInfoSkeleton: React.FC = () => {
  const classes = useSkeletonStyles();
  return (
    <Box className={classes.userInfoSkeleton}>
      <Skeleton variant="rect" width={150} height={20}></Skeleton>
      <span>·</span>
      <Skeleton variant="rect" width={150} height={20}></Skeleton>
      <span>·</span>
      <Skeleton variant="rect" width={150} height={20}></Skeleton>
    </Box>
  );
};

const UserInfo: React.FC<UserInfoProps> = ({ userLoading, phone, userInfo }) => {
  if (userLoading) {
    return <UserInfoSkeleton />;
  }

  const readeablePhone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
  if (!userInfo) {
    return (
      <Typography variant="subtitle1">No user found for phone number {readeablePhone}</Typography>
    );
  }

  const { firstName, lastName, email } = userInfo;
  return (
    <Typography variant="subtitle1">
      {firstName} {lastName} · {email} · {readeablePhone}
    </Typography>
  );
};

export default UserInfo;
