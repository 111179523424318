import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(${320 - theme.spacing(2) * 2}px, 1fr))`, // See card comp
    gridGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(3)
    },
    [theme.breakpoints.up('lg')]: {
      gridGap: theme.spacing(4)
    }
  },
  containerSpacing: {
    // margins all consistent with responsive card grid spacing
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3)
    }
  }
}));

const CardGrid = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.containerSpacing}>
      <Container>
        <Box className={classes.cardsContainer}>{children}</Box>
      </Container>
    </Box>
  );
};

export default CardGrid;
