import Box from '@material-ui/core/Box';
import Card, { CardProps } from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { ThemeProvider, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import PhotoIndicatorIcon from '@material-ui/icons/FilterSharp';
import OpenIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import get from 'lodash.get';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Request from '../../entities/Request';
import { contrastTheme } from '../../theme';

const { REACT_APP_MAPBOX_TOKEN } = process.env;
export interface RequestCardProps {
  request: Request;
}

const useMediaStyles = makeStyles({
  root: {
    height: 200,
    backgroundSize: 'contain'
  },
  map: {
    backgroundSize: 'cover'
  }
});

const useStyles = makeStyles((theme: any) => ({
  card: {
    minWidth: 320 - theme.spacing(2) * 2, // Support 320px device with min page horiz padding
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '& > *': {
      flex: '0 0 auto'
    }
  },
  createdAt: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    '& > :first-child': {
      marginRight: theme.spacing(1)
    }
  },
  cardContent: {
    flex: '1 0 auto'
  },
  description: {
    maxHeight: `calc(${theme.typography.body1.lineHeight} * 3)`,
    overflow: 'hidden'
  },
  cardActions: {
    paddingTop: 0
  }
}));

/** Media to present on card. Displays first photo (if present) or a map of the request location */
const PrimaryMedia: React.FC<Pick<Request, 'photos' | 'geometry'>> = ({ photos, geometry }) => {
  const classes = useMediaStyles();

  const firstPhoto = get(photos, '[0].src', null);
  if (firstPhoto) {
    return <CardMedia className={classes.root} image={firstPhoto}></CardMedia>;
  }

  const { latitude, longitude } = geometry.center;
  const map = `https://api.mapbox.com/styles/v1/ljagis/ck7dfd9sm0jnr1is1ig2c0t1z/static/pin-s+727c8a(${longitude},${latitude})/${longitude},${latitude},12,0.00,0.00/600x200@2x?access_token=${REACT_APP_MAPBOX_TOKEN}&logo=false`;
  return <CardMedia className={clsx(classes.root, classes.map)} image={map}></CardMedia>;
};

/**
 * RequestCard presents a card representing a request.
 * Props `request` is a Partial Request. To not show individual data on the card in different instances,
 * omit the prop not wanting to show from `request`.
 */
const RequestCard: React.FC<RequestCardProps & CardProps> = props => {
  const {
    request: { id, description, locationDisplay, photos, createdAt, geometry, workorderId },
    ...cardProps
  } = props;

  const theme = useTheme();
  const classes = useStyles({ ...props });
  const history = useHistory();
  const location = useLocation();
  const openDetails = () => {
    history.push({ pathname: `/app/requests/${id}`, state: { background: location } });
  };

  return (
    <Card className={classes.card} {...cardProps}>
      <CardHeader
        action={
          <IconButton onClick={openDetails}>
            <OpenIcon fontSize="small" />
          </IconButton>
        }
        title={locationDisplay}
      />
      <ThemeProvider theme={contrastTheme}>
        <Box position="relative" height={200} bgcolor="grey.800">
          <PrimaryMedia photos={photos} geometry={geometry} />
          {photos && photos.length > 1 && (
            <Box
              position="absolute"
              bottom={theme.spacing(2)}
              right={theme.spacing(2)}
              lineHeight={0}
              color="text.secondary"
            >
              <PhotoIndicatorIcon fontSize="small"></PhotoIndicatorIcon>
            </Box>
          )}
        </Box>
      </ThemeProvider>
      <CardContent className={classes.cardContent}>
        <Box className={classes.createdAt}>
          <EventIcon fontSize="small" />
          <Typography>{moment(createdAt).fromNow()}</Typography>
        </Box>
        <Typography className={classes.description}>{description}</Typography>
      </CardContent>
      {workorderId && (
        <Link
          href={`https://workorders.lukeapps.io/workorders/details/${workorderId}`}
          color="secondary"
          style={{ padding: '16px', paddingTop: 0 }}
        >
          Open in Work with LUKE
        </Link>
      )}
    </Card>
  );
};

export default RequestCard;
