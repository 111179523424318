import React from 'react';

import RequestCards from '../../components/RequestCards';
import useActiveRequests from '../../hooks/useActiveRequests';
import useQuickViews from '../../hooks/useQuickViews';

const ActiveRequestCardsContainer: React.FC = () => {
  const { active } = useQuickViews();
  const { requests, isLoading } = useActiveRequests({ requestFilter: active.filter });
  const noneFoundMessage = `All caught up on ${active.name.toLowerCase()} requests. There are none at this time.`;
  return (
    <RequestCards isLoading={isLoading} requests={requests} noneFoundMessage={noneFoundMessage} />
  );
};

export default ActiveRequestCardsContainer;
