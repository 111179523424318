import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import filter from 'lodash.filter';
import get from 'lodash.get';
import pick from 'lodash.pick';

import Request from '../entities/Request';
import { REQUEST_NODE } from '../graphql/fragments/requestNode';
import { RequestFilter } from '../interfaces';

export const QUERY = gql`
  query ActiveRequests {
    requests(isActive: true) {
      nodes {
        ...requestNode
      }
    }
  }
  ${REQUEST_NODE}
`;

export interface UseActiveRequestsProps {
  requestFilter?: RequestFilter;
}

/** Filter for subset of requests matching a filter */
const filterRequests = (requests: Request[], requestFilter: RequestFilter): Request[] =>
  // Apply more logic in the future for additional filtering. For now, just status.
  filter<Request>(requests, pick(requestFilter, ['status']));

/** Utility to help destructure optional props and apply as a request filter */
const applyFilterFromProps = (
  requests: Request[],
  props?: Pick<UseActiveRequestsProps, 'requestFilter'>
): Request[] => {
  const f = get(props, 'requestFilter');
  return f && requests.length ? filterRequests(requests, f) : requests;
};

const useActiveRequests = (props?: UseActiveRequestsProps) => {
  const { data, loading } = useQuery(QUERY, {
    displayName: 'useActiveRequests',
    fetchPolicy: 'cache-and-network'
  });

  const nodes = [...get(data, 'requests.nodes', [])].sort((a, b) => a.createdAt - b.createdAt);

  const requests = applyFilterFromProps(nodes, props);

  const refetch = (p?: UseActiveRequestsProps): Request[] => applyFilterFromProps(nodes, p);

  return {
    requests,
    isLoading: loading,
    refetch
  };
};

export default useActiveRequests;
