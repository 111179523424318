import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';

import useCurrentUser from '../../hooks/useCurrentUser';

export interface CommentManagerProps {
  postComment: (content: string) => void;
}

const useStyles = makeStyles({
  commentControl: {
    flex: '1 1 auto'
  },
  input: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1
    }
  }
});

const CommentManager: React.FC<CommentManagerProps> = ({ postComment }) => {
  const classes = useStyles();

  const [currentUser] = useCurrentUser();
  const { firstName, lastName } = currentUser || { firstName: ' ', lastName: ' ' };
  const initials = firstName.charAt(0) + lastName.charAt(0);

  const [content, setContent] = useState('');

  const updateContentBuffer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setContent(value);
  };

  const clearContentBuffer = () => {
    setContent('');
  };

  const save = () => {
    if (!content.length) {
      return;
    }

    postComment(content);
    clearContentBuffer();
  };

  return (
    <>
      <Box display="flex" alignItems="center" my={2}>
        <Box mr={2} flex="0 0 auto">
          <Avatar>{currentUser ? initials : <PersonIcon />}</Avatar>
        </Box>
        <FormControl className={classes.commentControl} variant="filled">
          <OutlinedInput
            className={classes.input}
            autoComplete="off"
            labelWidth={70}
            multiline={true}
            notched={false}
            onChange={updateContentBuffer}
            placeholder="Add a comment..."
            value={content}
          />
        </FormControl>
      </Box>
      <Grid container justify="flex-end" spacing={1}>
        <Grid item>
          <Button onClick={clearContentBuffer} disabled={!content.length}>
            Clear
          </Button>
        </Grid>
        <Grid item>
          <Button color="secondary" onClick={save} disabled={!content.length}>
            Post Comment
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CommentManager;
