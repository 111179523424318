import { TextField, BaseTextFieldProps } from '@material-ui/core';
import React from 'react';
import { Rifm } from 'rifm';

interface EmailInputProps extends Partial<BaseTextFieldProps> {
  value: string;
  onChange: (arg0: any) => void;
  label?: string;
}

const EmailInput: React.FC<EmailInputProps> = ({ onChange, value, label, ...inputProps }) => {
  const regex = /[a-zA-Z0-9_.+-@]/g;
  return (
    <Rifm
      accept={regex}
      format={v => (v.match(regex) || []).join('')}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y }: any) => (
        <TextField
          {...inputProps}
          variant="standard"
          label={label}
          type="text"
          value={y}
          onChange={x}
          placeholder="first.last@email.com"
        />
      )}
    </Rifm>
  );
};

export default EmailInput;
