import { gql } from 'apollo-boost';

export const initialState = {
  message: {
    __typename: 'Message',
    code: null,
    color: null,
    content: null
  }
};

export const resolvers = {
  Mutation: {
    updateMessage: (_: any, params: any, { cache }: any) => {
      const query = gql`
        query GetMessage {
          message @client {
            color
            code
            content
          }
        }
      `;

      const { message } = cache.readQuery({ query });
      const { input } = params;
      Object.keys(input).forEach(key => input[key] === undefined && delete input[key]);
      const data = {
        message: {
          ...message,
          ...input
        }
      };

      cache.writeQuery({ query, data });
      return data.message;
    }
  }
};
