import { TextField, BaseTextFieldProps } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Rifm } from 'rifm';

interface CodeInputProps extends Partial<BaseTextFieldProps> {
  label?: string;
  value: string;
  onChange: (arg0: any) => void;
}

const useStyles = makeStyles({
  input: {
    textAlign: 'center'
  }
});

const parseDigits = (s: string) => (s.match(/\d+/g) || []).join('');
const formatCode = (s: string) => {
  return parseDigits(s).substring(0, 6);
};

const CodeInput: React.FC<CodeInputProps> = ({ label, onChange, value, ...inputProps }) => {
  const classes = useStyles();

  return (
    <Rifm
      accept={/[\d.$]/g}
      mask={value.length < 6 ? undefined : value.length === 6}
      format={formatCode}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y }: any) => (
        <TextField
          {...inputProps}
          variant="standard"
          label={label}
          type="tel"
          value={y}
          onChange={x}
          placeholder="******"
          className={classes.input}
        />
      )}
    </Rifm>
  );
};

export default CodeInput;
