import { useApolloClient, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const ACCESS_TOKEN = gql`
  query AccessToken {
    accessToken @client
  }
`;

const useAccessToken = () => {
  const client = useApolloClient();
  const { data, loading } = useQuery(ACCESS_TOKEN, { displayName: 'useAccessToken' });
  const accessToken = data && data.accessToken;

  const saveAccessToken = async (t: string) => client.writeData({ data: { accessToken: t } });

  const logout = async () => client.resetStore();

  const getTokenData = () => {
    if (accessToken && accessToken.length > 0) {
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    }

    return {};
  };

  const { exp } = getTokenData();
  const isAuthenticated = exp && exp * 1000 >= Date.now();

  return {
    loading,
    getTokenData,
    saveAccessToken,
    isAuthenticated,
    accessToken,
    logout
  };
};

export default useAccessToken;
