import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';
import React, { ReactNode, useState } from 'react';

export interface Photo {
  /** URL to image */
  route: string;
  customIcon?: {
    selected: (props: any) => ReactNode;
    unselected: (props: any) => ReactNode;
  };
  /**
   * `false` or not defined (default) - photo will be contained in the carousel without any clipping.
   * `true` - photo should cover available area and may possibly be clipped.
   */
  cover?: boolean;
}

export interface CarouselProps {
  photos: Photo[];
}

type Direction = 'left' | 'right' | 'up' | 'down';

const useStyles = makeStyles(theme =>
  createStyles({
    image: {
      backgroundSize: 'contain',
      height: '100%'
    },
    cover: {
      backgroundSize: 'cover'
    },
    gradientOverlay: {
      backgroundImage:
        'linear-gradient(to top, rgba(0,0,0,0.12) 10%, rgba(0,0,0,0.08) 20%, rgba(0,0,0,0.54) 80%)'
    },
    rotateButton: {
      height: '100%',
      padding: '1px', // With internal button padding, 1px makes icon 12px (1.5 spacing) from edge
      position: 'absolute',
      width: 'auto',
      minWidth: 'auto', // Overwrite material-ui setting minWidth

      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    leftRotateButton: {
      justifyContent: 'flex-start',
      left: 0
    },
    rightRotateButton: {
      justifyContent: 'flex-end',
      right: 0
    },
    navButton: {
      padding: theme.spacing(0.5),

      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    navButtonIcon: {
      height: '16px',
      width: '16px'
    }
  })
);

const Carousel: React.FC<CarouselProps> = ({ photos }: CarouselProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [previousIndex, setPreviousIndex] = useState<number>(0);
  const [direction, setDirection] = useState<Direction>('left');

  const isSelectedIndex = (index: number): boolean => {
    return index === selectedIndex;
  };

  const getLeft = (): number => {
    return (selectedIndex + photos.length - 1) % photos.length;
  };

  const getRight = (): number => {
    return (selectedIndex + 1) % photos.length;
  };

  const rotateAdjacent = (dest: number) => {
    setDirection(dest === getRight() ? 'left' : 'right');
    setPreviousIndex(selectedIndex);
    setSelectedIndex(dest);
  };

  const rotateClockwise = () => {
    rotateAdjacent(getRight());
  };

  const rotateCounterclockwise = () => {
    rotateAdjacent(getLeft());
  };

  const navigateTo = (dest: number) => {
    setDirection(dest > selectedIndex ? 'left' : 'right');
    setPreviousIndex(selectedIndex);
    setSelectedIndex(dest);
  };

  return photos.length ? (
    <LayerGroup>
      {photos.length > 1 && (
        <Layer zIndex={1}>
          <OutgoingContent previousIndex={previousIndex} photos={photos} />
        </Layer>
      )}

      <Layer zIndex={2}>
        <IncomingContent direction={direction} isSelectedIndex={isSelectedIndex} photos={photos} />
      </Layer>

      {photos.length > 1 && (
        <Layer zIndex={4}>
          <Controls
            isSelectedIndex={isSelectedIndex}
            photos={photos}
            rotateClockwise={rotateClockwise}
            rotateCounterclockwise={rotateCounterclockwise}
            navigateTo={navigateTo}
          />
        </Layer>
      )}
    </LayerGroup>
  ) : (
    <Box />
  );
};

export default Carousel;

const LayerGroup = ({ children }) => (
  // z-index applied to reset the z-index context
  // and layer z-index aren't mixed with z-indexes outside component
  <Box height="100%" position="relative" width="100%" zIndex={0}>
    {children}
  </Box>
);

const Layer = ({ zIndex, children }) => (
  <Box height="100%" left={0} position="absolute" top={0} width="100%" zIndex={zIndex}>
    {children}
  </Box>
);

const OutgoingContent = ({ photos, previousIndex }) => {
  const classes = useStyles();

  return (
    <Box bgcolor="grey.800" height="100%" width="100%">
      <CardMedia
        className={clsx(classes.image, { [classes.cover]: photos[previousIndex].cover })}
        image={photos[previousIndex].route}
        title="image"
      />
    </Box>
  );
};

const IncomingContent = ({ direction, isSelectedIndex, photos }) => {
  const classes = useStyles();

  return (
    <Box height="100%" overflow="hidden" width="100%">
      {photos.map((photo: Photo, index: number) => (
        <Slide
          appear={false}
          direction={direction}
          exit={false}
          in={isSelectedIndex(index)}
          key={index}
          mountOnEnter
          timeout={250}
          unmountOnExit
        >
          <Box bgcolor="grey.800" height="100%" width="100%">
            <CardMedia
              className={clsx(classes.image, { [classes.cover]: photo.cover })}
              image={photo.route}
              title="image"
            />
          </Box>
        </Slide>
      ))}
    </Box>
  );
};

const Controls = ({
  isSelectedIndex,
  photos,
  rotateClockwise,
  rotateCounterclockwise,
  navigateTo
}) => {
  return (
    <Box height="100%" width="100%">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        position="relative"
        width="100%"
      >
        <RotateButtons
          rotateClockwise={rotateClockwise}
          rotateCounterclockwise={rotateCounterclockwise}
        />
        <NavigationButtons
          isSelectedIndex={isSelectedIndex}
          photos={photos}
          navigateTo={navigateTo}
        />
      </Box>
    </Box>
  );
};

const RotateButtons = ({ rotateClockwise, rotateCounterclockwise }) => {
  const classes = useStyles();

  return (
    <Box flex="auto" position="relative" width="100%">
      <Button
        className={clsx(classes.rotateButton, classes.leftRotateButton)}
        onClick={rotateCounterclockwise}
        disableRipple
      >
        <NavigateBeforeIcon fontSize="large" />
      </Button>
      <Button
        className={clsx(classes.rotateButton, classes.rightRotateButton)}
        onClick={rotateClockwise}
        disableRipple
      >
        <NavigateNextIcon fontSize="large" />
      </Button>
    </Box>
  );
};

const NavigationButtons = ({ isSelectedIndex, navigateTo, photos }) => {
  const classes = useStyles();

  return (
    <Box bottom={6} position="absolute">
      {/* Use 6 px bottom to space icons theme.spacing(1.5) (12px). Offset button padding */}
      {photos.map((photo: Photo, index: number) => (
        <IconButton
          aria-label="navigation button"
          className={classes.navButton}
          key={index}
          onClick={() => navigateTo(index)}
        >
          {photo.customIcon ? (
            <CustomIcon isSelected={isSelectedIndex(index)} icon={photo.customIcon} />
          ) : (
            <DefaultIcon isSelected={isSelectedIndex(index)} />
          )}
        </IconButton>
      ))}
    </Box>
  );
};

const CustomIcon = ({ icon, isSelected }) => {
  const classes = useStyles();
  const { selected: Selected, unselected: Unselected } = icon;

  return isSelected ? (
    <Selected className={classes.navButtonIcon} />
  ) : (
    <Unselected className={classes.navButtonIcon} />
  );
};

const DefaultIcon = ({ isSelected }) => {
  const classes = useStyles();

  return isSelected ? (
    <RadioButtonCheckedIcon className={classes.navButtonIcon} />
  ) : (
    <RadioButtonUncheckedIcon className={classes.navButtonIcon} />
  );
};
