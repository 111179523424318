// Temporary state until these can be moved closer to their resolvers

const initialState = {
  errorSnackbar: {
    __typename: 'ErrorSnackbar',
    isOpen: false,
    message: ''
  }
};

export default initialState;
