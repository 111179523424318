export const isMobile = {
  Android: (): boolean => {
    return Boolean(navigator.userAgent.match(/Android/i));
  },
  BlackBerry: (): boolean => {
    return Boolean(navigator.userAgent.match(/BlackBerry/i));
  },
  iOS: (): boolean => {
    return Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));
  },
  Opera: (): boolean => {
    return Boolean(navigator.userAgent.match(/Opera Mini/i));
  },
  Windows: (): boolean => {
    return Boolean(
      navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: (): boolean =>
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows()
};
