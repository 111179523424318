import { useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { gql } from 'apollo-boost';
import React from 'react';

import { Comment as IComment } from '../../entities/Request';
import Comment from '../Comment';
import CommentManager from '../CommentManager';

export interface RequestCommentsProps {
  comments: IComment[];
  requestId: string | null;
}

const POST_COMMENT = gql`
  mutation PostComment($input: AddRequestCommentInput!) {
    addRequestComment(input: $input) {
      request {
        id
        comments {
          id
          author
          content
          createdAt
        }
      }
    }
  }
`;

const RequestComments: React.FC<RequestCommentsProps> = ({ comments, requestId }) => {
  const [addRequestComment] = useMutation(POST_COMMENT);

  const postComment = (content: string) => {
    if (!(requestId && content.length)) {
      return;
    }

    const input = { requestId, content };
    addRequestComment({ variables: { input } });
  };

  const CommentsList = () => (
    <>
      {comments.map((props, index) => (
        <Comment key={index} {...props} />
      ))}
    </>
  );

  return (
    <Box>
      <Box my={2}>
        <Typography color="primary" variant="subtitle1">
          Comments
        </Typography>
      </Box>
      <CommentsList />
      {comments.length ? <Divider /> : <></>}
      <CommentManager postComment={postComment} />
    </Box>
  );
};

export default RequestComments;
