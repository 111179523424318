import React from 'react';

import Request from '../../entities/Request';
import Carousel from '../Carousel';
import { Photo } from '../Carousel/Carousel';

const { REACT_APP_MAPBOX_TOKEN } = process.env;

export interface RequestPhotosCarouselProps {
  request: Pick<Request, 'photos' | 'geometry'>;
}

const RequestPhotosCarousel: React.FC<RequestPhotosCarouselProps> = ({
  request: { photos, geometry }
}: RequestPhotosCarouselProps) => {
  const mappedPhotos: Photo[] = photos.map(
    (photo): Photo => ({
      route: photo.src
    })
  );

  const { longitude, latitude } = geometry.center;
  mappedPhotos.push({
    route: `https://api.mapbox.com/styles/v1/ljagis/ck7dfd9sm0jnr1is1ig2c0t1z/static/pin-s+727c8a(${longitude},${latitude})/${longitude},${latitude},12,0.00,0.00/900x400@2x?access_token=${REACT_APP_MAPBOX_TOKEN}&logo=false`,
    cover: true
  });

  return <Carousel photos={mappedPhotos} />;
};

export default RequestPhotosCarousel;
