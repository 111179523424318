import { useQuery } from '@apollo/react-hooks';
import { BoxProps } from '@material-ui/core';
import { gql } from 'apollo-boost';
import get from 'lodash.get';
import React from 'react';

import useAccessToken from '../../hooks/useAccessToken';
import UserMenu from '../UserMenu';

const USER = gql`
  query UserProfile($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

const UserMenuContainer: React.FC<BoxProps> = ({ ...boxProps }) => {
  const { logout, getTokenData } = useAccessToken();
  const { id } = getTokenData();
  const { data, loading } = useQuery(USER, {
    variables: { userId: id },
    displayName: 'UserMenuContainer'
  });
  const user = get(data, 'user');
  const userReady = user && !loading;

  const initials = user && user.firstName.charAt(0) + user.lastName.charAt(0);

  return (
    <UserMenu
      user={
        userReady
          ? {
              ...user,
              initials
            }
          : null
      }
      onLogout={logout}
      {...boxProps}
    ></UserMenu>
  );
};

export default UserMenuContainer;
