import { useQuery } from '@apollo/react-hooks';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { gql } from 'apollo-boost';
import React from 'react';

import RequestDetails from '../RequestDetails';
import RequestDetailsSkeleton from '../RequestDetails/RequestDetailsSkeleton';

export interface RequestDetailsContainerProps {
  id: string;
}

export const REQUEST = gql`
  query request($id: ID!) {
    request(id: $id) {
      id
      actions @client
      category
      comments {
        id
        content
        author
        createdAt
      }
      createdAt
      createdBy
      description
      geometry {
        center {
          latitude
          longitude
        }
      }
      modifiedAt
      location {
        address
      }
      photos {
        id
        src
      }
      status
      workorderId
    }
  }
`;

export const USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

const RequestDetailsContainer: React.FC<RequestDetailsContainerProps> = ({ id }) => {
  const { loading, data } = useQuery(REQUEST, { variables: { id }, pollInterval: 10000 });
  const userId = data && data.request && data.request.createdBy;
  const { loading: userQueryLoading, data: userQueryData } = useQuery(USER, {
    skip: !userId,
    variables: { id: userId }
  });

  if (loading) {
    return <RequestDetailsSkeleton />;
  }

  if (!data.request) {
    // TODO Create request not found page
    return (
      <Box display="flex" justifyContent="center" height={300} mt={6}>
        <Typography variant="h5" component="h5">
          Request not found
        </Typography>
      </Box>
    );
  }

  return (
    <RequestDetails
      request={data.request}
      userQueryLoading={userQueryLoading}
      userQueryData={userQueryData}
    />
  );
};

export default RequestDetailsContainer;
