import { ApolloProvider } from '@apollo/react-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import App from './App';
import Auth from './pages/Auth';
import ProtectedRoute from './pages/Auth/ProtectedRoute';
import { StoreService } from './services/StoreService';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';
import { version } from '../package.json';

const store = new StoreService(version);
const client = store.getClient();

ReactDOM.render(
  <ApolloProvider client={client}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute path="/app" component={App} />
          <Route path="/auth" component={Auth} />
          <Route path="/" render={() => <Redirect to="/app" />} exact={true} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
