import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback, useEffect, useState } from 'react';

export interface Message {
  color?: string;
  code?: string;
  content: string;
}

export const GET_MESSAGE = gql`
  query GetMessage {
    message @client {
      code
      color
      content
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation updateMessage($content: String!, $color: String, $code: String) {
    updateMessage(input: { content: $content, color: $color, code: $code }) @client
  }
`;

const useMessage = () => {
  const [display, setDisplay] = useState<boolean>(false);
  const { data, loading } = useQuery(GET_MESSAGE);
  const { message } = data;
  const [updateMessage] = useMutation(UPDATE_MESSAGE);

  useEffect(() => {
    message.content && message.content.length > 0 ? setDisplay(true) : setDisplay(false);
  }, [message.content, setDisplay]);

  const setMessage = useCallback(
    async (msg: Message) => {
      await updateMessage({
        variables: {
          code: msg.code || 'UnknownCode',
          content: msg.content || 'Something went wrong',
          color: msg.color || 'danger'
        }
      });
    },
    [updateMessage]
  );

  const clearMessage = useCallback(async () => {
    await updateMessage({
      variables: {
        code: null,
        color: null,
        content: null
      }
    });
  }, [updateMessage]);

  return {
    display,
    loading,
    message,
    setMessage,
    clearMessage
  };
};

export default useMessage;
