import React from 'react';

import CardGrid from '../../components/CardGrid';
import PageHeader from '../../components/PageHeader';
import RequestCards from '../../components/RequestCards';
import UserInfo from '../../components/UserInfo';
import useRouterPath from '../../hooks/useRouterPath';
import useSearchRequests from '../../hooks/useSearchRequests';
const SearchRequestCardsContainer: React.FC = () => {
  const { params } = useRouterPath();
  const phone = params.phone;
  const { requests, userInfo, isLoading, userLoading } = useSearchRequests({
    phone
  });

  return (
    <>
      <PageHeader>
        <UserInfo userLoading={userLoading} phone={phone} userInfo={userInfo} />
      </PageHeader>
      <CardGrid>
        {userInfo ? (
          <RequestCards
            isLoading={isLoading}
            requests={requests}
            noneFoundMessage={`No requests found for ${userInfo.firstName} ${userInfo.lastName}`}
          />
        ) : null}
      </CardGrid>
    </>
  );
};

export default SearchRequestCardsContainer;
