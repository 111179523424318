import { gql } from 'apollo-boost';

import RequestActionBase from './RequestAction';

export const START_DENY = gql`
  mutation startDenyRequest($requestId: string!) {
    startDenyRequest(input: { requestId: $requestId }) @client
  }
`;

export default class DenyAction extends RequestActionBase {
  title = 'Deny';
  action(requestId: string) {
    this.mutateFn({ mutation: START_DENY, variables: { requestId } });
  }
}
