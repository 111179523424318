import TextField from '@material-ui/core/TextField';
import { BaseTextFieldProps } from '@material-ui/core';
import { AsYouType } from 'libphonenumber-js';
import React from 'react';
import { Rifm } from 'rifm';

interface PhoneInputProps extends Partial<BaseTextFieldProps> {
  value: string;
  onChange: (arg0: any) => void;
  label?: string;
}

const parseDigits = (s: string) => (s.match(/\d+/g) || []).join('');
const formatPhone = (s: string) => {
  const digits = parseDigits(s).substring(0, 10);
  return new AsYouType('US').input(digits);
};

const PhoneInput: React.FC<PhoneInputProps> = ({ onChange, value, label, ...inputProps }) => {
  return (
    <Rifm
      accept={/\d+/g}
      // do not jump after ) until see number before
      mask={value.length < 6 && /[^\d]+/.test(value[3]) ? undefined : value.length >= 16}
      format={formatPhone}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y }: any) => (
        <TextField
          {...inputProps}
          label={label}
          variant="standard"
          type="tel"
          value={y}
          onChange={x}
          placeholder="(999) 999-9999"
        />
      )}
    </Rifm>
  );
};

export default PhoneInput;
