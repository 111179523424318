import {
  BaseTextFieldProps,
  IconButton,
  InputAdornment,
  createStyles,
  makeStyles
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import { AsYouType } from 'libphonenumber-js';
import React from 'react';
import { Rifm } from 'rifm';

interface SearchPhoneInputProps extends Partial<BaseTextFieldProps> {
  value: string;
  onChange: (phoneNumber: string) => void;
  onClear: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 215
    },
    notchedOutline: {
      border: 0
    }
  })
);

const useAdornmentStyles = makeStyles((theme: any) =>
  createStyles({
    start: {
      color: theme.palette.text.secondary
    },
    end: {
      visibility: (props: any) => (props.value.length ? 'visible' : 'hidden')
    }
  })
);

const parseDigits = (s: string) => (s.match(/\d+/g) || []).join('');
const formatPhone = (s: string) => {
  const digits = parseDigits(s).substring(0, 10);
  return new AsYouType('US').input(digits);
};

const SearchPhoneInput: React.FC<SearchPhoneInputProps> = ({
  onChange,
  onClear,
  value,
  ...inputProps
}) => {
  const classes = useStyles();
  const adornmentClasses = useAdornmentStyles({ value });
  return (
    <Rifm
      accept={/\d+/g}
      // do not jump after ) until see number before
      mask={value.length < 6 && /[^\d]+/.test(value[3]) ? undefined : value.length >= 16}
      format={formatPhone}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y }: any) => (
        <TextField
          {...inputProps}
          variant={'outlined'}
          type="tel"
          value={y}
          onChange={x}
          placeholder={'Search'}
          InputProps={{
            classes,
            startAdornment: (
              <InputAdornment className={adornmentClasses.start} position="start">
                <PhoneEnabledIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton className={adornmentClasses.end} onClick={onClear}>
                <ClearIcon />
              </IconButton>
            )
          }}
        />
      )}
    </Rifm>
  );
};

export default SearchPhoneInput;
