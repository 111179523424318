import Avatar from '@material-ui/core/Avatar';
import Box, { BoxProps } from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React from 'react';

import { User } from '../../entities/User';

export interface UserMenuProps {
  user?: User;
  onLogout: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      width: 40,
      height: 40
    },
    clickable: {
      cursor: 'pointer'
    }
  })
);

const UserMenu: React.FC<UserMenuProps & BoxProps> = ({ user, onLogout, ...boxProps }) => {
  const classes = useStyles();

  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const openMenu = anchor => {
    setMenuAnchor(anchor);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const onAvatarClick = event => {
    openMenu(event.currentTarget);
  };

  if (!user) {
    // Skeletoned version when there is no user value
    return (
      // testid used by tests to determine if element is rendered
      <Box {...boxProps} data-testid="usermenu-skeleton">
        <Skeleton variant="circle" className={classes.avatar} />
      </Box>
    );
  }

  return (
    <Box {...boxProps}>
      <Avatar
        src={user.picture}
        alt={user.initials}
        className={clsx(classes.avatar, classes.clickable)}
        onClick={onAvatarClick}
      >
        {/* NOTE. Initials will probably never get used. Auth0 always returns picture. */}
        {user.initials}
      </Avatar>
      <Menu id="usermenu-menu" anchorEl={menuAnchor} open={!!menuAnchor} onClose={closeMenu}>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
