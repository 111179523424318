import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import DenyRequestDialogContainer from './components/DenyRequestDialogContainer';
import ErrorSnackbarContainer from './components/ErrorSnackbarContainer';
import RequestDetailsModal from './components/RequestDetailsModal';
import SearchPhoneInputContainer from './components/SearchPhoneInputContainer';
import UserMenuContainer from './components/UserMenuContainer';
import useRouterPath from './hooks/useRouterPath';
import RequestDetailsPage from './pages/RequestDetailsPage';
import RequestsIndexPage from './pages/RequestsIndexPage';
import SearchIndexPage from './pages/SearchIndexPage';

const useStyles = makeStyles((theme: any) => ({
  '@global': {
    body: {
      background: theme.palette.background.default
    }
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const RouterSwitches = () => {
  const { location, history } = useRouterPath();
  let background;
  if (history.action === 'PUSH') {
    background = location.state && location.state.background;
  }

  return (
    <>
      <Switch location={background || location}>
        <Route exact path="/app/requests/:id">
          <RequestDetailsPage />
        </Route>
        <Route exact path="/app/requests">
          <RequestsIndexPage />
        </Route>
        <Route path="/app/search">
          <SearchIndexPage />
        </Route>
        <Route path="/app">
          <Redirect to="/app/requests" />
        </Route>
      </Switch>
      {background && (
        <Route path="/app/requests/:id">
          <RequestDetailsModal />
        </Route>
      )}
    </>
  );
};

const App: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.appBar} maxWidth={false}>
        <SearchPhoneInputContainer />
        <UserMenuContainer />
      </Container>
      <Divider />
      <RouterSwitches />
      <DenyRequestDialogContainer />
      <ErrorSnackbarContainer />
    </>
  );
};

export default App;
