import { gql } from 'apollo-boost';

import RequestActionBase from './RequestAction';

export const COMPLETE = gql`
  mutation completeRequest($requestId: ID!) {
    completeRequest(input: { requestId: $requestId }) {
      request {
        id
        status
        actions @client
      }
    }
  }
`;

export default class CompleteAction extends RequestActionBase {
  title = 'Complete';
  action(requestId: string) {
    this.mutateFn({ mutation: COMPLETE, variables: { requestId } });
  }
}
