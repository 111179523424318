import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import get from 'lodash.get';

import useAccessToken from './useAccessToken';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

const useCurrentUser = () => {
  const { getTokenData } = useAccessToken();
  const { id } = getTokenData();
  const { data } = useQuery(GET_CURRENT_USER, { variables: { userId: id } });
  return [get(data, 'user')];
};

export default useCurrentUser;
