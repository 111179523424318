import useAccessToken from '../../hooks/useAccessToken';
import { isMobile } from '../../utils/isMobile';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

const ProtectedRoute: React.FC<RouteProps & { component: any }> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated, loading } = useAccessToken();
  const defaultPath = isMobile.any() ? `/auth/phone` : `/auth/email`;

  return (
    <Route
      {...rest}
      render={props =>
        !loading && isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={defaultPath} />
        )
      }
    />
  );
};

export default ProtectedRoute;
