import { User } from '../entities/User';
import { GraphQLEntity } from './GraphQLEntity';
import { gql } from 'apollo-boost';
import { isMobile } from '../utils/isMobile';

export const initialState = {
  authFlow: {
    __typename: 'AuthFlow',
    step: 'CHALLENGE_USER',
    method: isMobile.any() ? 'Phone' : 'Email',
    userId: null,
    challengeId: null,
    recipient: null,
    reissue: false
  }
};

export const resolvers = {
  Query: {
    /** Current authenticated user or `null` if no user is logged in */
    async user(): Promise<(User & GraphQLEntity) | null> {
      return null;
      // return { ...user, __typename: 'User' };
    }
  },
  Mutation: {
    updateAuthFlow: async (_: any, params: any, { cache }: any) => {
      const query = gql`
        query AuthFlow {
          authFlow @client {
            step
            method
            userId
            challengeId
            recipient
            reissue
          }
        }
      `;

      const { input } = params;
      const { authFlow } = cache.readQuery({ query });
      Object.keys(input).forEach(key => input[key] === undefined && delete input[key]);
      const data = {
        authFlow: {
          ...authFlow,
          ...input
        }
      };

      cache.writeQuery({ query, data });

      return data.authFlow;
    }
  }
};
