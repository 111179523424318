export const resolvers = {
  Mutation: {
    showError: (_, { input: { message } }, { cache }) => {
      cache.writeData({
        data: { errorSnackbar: { message, isOpen: true, __typename: 'ErrorSnackbar' } }
      });
    },
    hideError: (_, __, { cache }) => {
      cache.writeData({
        data: { errorSnackbar: { message: '', isOpen: false, __typename: 'ErrorSnackbar' } }
      });
    }
  }
};
