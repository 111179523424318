import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import find from 'lodash.find';
import omitDeep from 'omit-deep';

import { QuickView } from '../entities/QuickView';
import { QuickViewState } from '../graphql/quickView';

export const QUERY = gql`
  query QuickViews {
    quickViews @client {
      id
      name
      filter {
        status
      }
    }
    activeQuickView @client
  }
`;

export const SET_ACTIVE = gql`
  mutation SetActiveQuickView($quickViewId: string!) {
    setActiveQuickView(input: { id: $quickViewId }) @client
  }
`;

const fromState = (state: QuickViewState): QuickView => ({
  ...(omitDeep(state, ['__typename']) as Omit<QuickViewState, '__typename'>),
  isActive: false
});

const useQuickViews = () => {
  const query = useQuery(QUERY, {
    displayName: 'useQuickViews'
  });

  const [setActiveMutation] = useMutation(SET_ACTIVE);

  const {
    quickViews: quickViewState,
    activeQuickView: activeState
  }: { quickViews: QuickViewState[]; activeQuickView: string } = query.data;

  const quickViews: QuickView[] = quickViewState.map(fromState);

  const active = find<QuickView>(quickViews, { id: activeState });

  if (!active) {
    // TODO. Decide how to most gracefully handle this situation
    throw new Error(
      'There was a configuration error. `activeQuickView` in the Apollo cache does not match any Quick View IDs.'
    );
  }

  active.isActive = true;

  return {
    quickViews,
    active,
    setActive: (quickViewId: string) => setActiveMutation({ variables: { quickViewId } })
  };
};

export default useQuickViews;
